const appV2EventPrefix = "HCF Admin Web App (V2):";
const FACILITY_RULES_AI_PREFIX = "Facility Rules AI";
const HOME_HEALTH_EVENT_PREFIX = "Home Health";
const FACILITY_INVOICE_HISTORY_EVENT_PREFIX = "Facility Invoice History";
const INVOICE_DISPUTE_EVENT_PREFIX = "Invoice Dispute";

export const APP_V2_USER_EVENTS = {
  // Facility Rules AI
  FACILITY_RULES_AI_VISITED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} page visited`,
  FACILITY_RULES_AI_QUIZ_REVIEWED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz reviewed`,
  FACILITY_RULES_AI_NOTES_UPDATED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} notes updated`,
  FACILITY_RULES_AI_QUIZ_UPDATED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz updated`,
  FACILITY_RULES_AI_QUIZ_UPDATE_CANCEL_CLICKED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz update cancel clicked`,
  FACILITY_RULES_AI_QUIZ_UPDATE_CONTINUE_CLICKED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz update continue clicked`,
  FACILITY_RULES_AI_QUIZ_TOGGLED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz toggled`,
  FACILITY_RULES_AI_QUIZ_DRAWER_CLOSED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz drawer closed`,

  FACILITY_RULES_AI_QUIZ_QUESTION_TEXT_REMOVED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz question text removed`,
  FACILITY_RULES_AI_QUIZ_OPTION_TEXT_REMOVED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz option text removed`,
  FACILITY_RULES_AI_NOTES_CHARACTER_OUT_OF_RANGE_WARNING: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} notes character out of range warning`,

  // Home Health
  HOME_HEALTH_CASE_CREATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} case created`,
  HOME_HEALTH_CASE_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} case updated`,
  HOME_HEALTH_CASE_CLOSED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} case closed`,
  HOME_HEALTH_CASE_REOPENED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} case reopened`,
  HOME_HEALTH_PATIENT_CREATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} patient created`,
  HOME_HEALTH_PATIENT_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} patient updated`,
  HOME_HEALTH_VISIT_CREATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit created`,
  HOME_HEALTH_VISIT_CREATED_WITH_CASE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit created with case`,
  HOME_HEALTH_VISIT_CREATED_AFTER_CASE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit created after case`,
  HOME_HEALTH_VISIT_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit updated`,
  HOME_HEALTH_VISIT_CANCELED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit canceled`,
  HOME_HEALTH_VISIT_WORKER_INVITED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit worker invited`,
  HOME_HEALTH_VISIT_WORKER_BOOKED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit worker booked`,
  HOME_HEALTH_VISIT_WORKER_REMOVED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit worker removed`,
  HOME_HEALTH_VISIT_OCCURRENCE_REJECTED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence rejected`,
  HOME_HEALTH_VISIT_OCCURRENCE_VERIFIED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence verified`,
  HOME_HEALTH_VISIT_OCCURRENCE_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence updated`,
  HOME_HEALTH_VISIT_OCCURRENCE_PAID: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence set paid`,
  HOME_HEALTH_VISIT_OCCURRENCE_NOT_PAID: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence set not paid`,
  HOME_HEALTH_ACCOUNT_PRICING_CREATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} account pricing created`,
  HOME_HEALTH_ACCOUNT_PRICING_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} account pricing updated`,
  HOME_HEALTH_ACCOUNT_PRICING_DELETED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} account pricing deleted`,
  HOME_HEALTH_TRUSTED_PROVIDER_ADDED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} trusted provider added`,
  HOME_HEALTH_TRUSTED_PROVIDER_REMOVED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} trusted provider removed`,

  // Facility Invoice History Page
  INVOICE_HISTORY_VIEWED: `${appV2EventPrefix} ${FACILITY_INVOICE_HISTORY_EVENT_PREFIX} Invoice History Viewed`,
  INVOICE_VIEW_CLICKED: `${appV2EventPrefix} ${FACILITY_INVOICE_HISTORY_EVENT_PREFIX} Invoice View Clicked`,
  INVOICE_PAY_CLICKED: `${appV2EventPrefix} ${FACILITY_INVOICE_HISTORY_EVENT_PREFIX} Clicked to Pay Invoice`,
  INVOICE_VIEW_DISPUTE_CLICKED: `${appV2EventPrefix} ${FACILITY_INVOICE_HISTORY_EVENT_PREFIX} Invoice View Dispute Button Clicked`,

  // Facility Upcoming Charges
  UPCOMING_CHARGES_VIEWED: `${appV2EventPrefix} Upcoming Charges Viewed`,
  UPCOMING_CHARGES_DATE_RANGE_CHANGED: `${appV2EventPrefix} Upcoming Charges Dates Changed`,
  UPCOMING_CHARGES_VERIFY_SHIFT_POPUP_OPENED: `${appV2EventPrefix} Upcoming Charges Verify Shift Popup Opened`,
  UPCOMING_CHARGES_VERIFY_SHIFT_BUTTON_CLICKED: `${appV2EventPrefix} Upcoming Charges Verify Shift Button Clicked`,

  // Invoice Dispute
  INVOICE_DISPUTE_BUTTON_CLICKED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Dispute Button Clicked`,
  INVOICE_DISPUTE_DRAFT_SAVED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Item Dispute Draft Saved`,
  INVOICE_ITEM_DISPUTE_DISCARD_BUTTON_CLICKED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Item Discard Button Clicked`,
  INVOICE_DISPUTE_DISCARD_ALL_BUTTON_CLICKED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Dispute Discard All Button Clicked`,
  INVOICE_DISPUTE_SUBMITTED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Dispute Submitted`,
  INVOICE_DISPUTE_UPDATED_HOURS_PREFERENCE: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} updated dispute time preferences`,
  INVOICE_DISPUTE_BLOCK_NAVIGATION_TRIGGERED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} block navigation triggered`,
  INVOICE_DISPUTE_HOURS_FORMAT_TOOLTIP_CLOSED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} hours format tooltip closed`,

  // Shift Block
  SHIFT_BLOCK_CREATED: `${appV2EventPrefix} Shift Block Created`,
  SHIFT_BLOCK_DELETED: `${appV2EventPrefix} Shift Block Deleted`,
  SHIFT_BLOCK_UPDATED: `${appV2EventPrefix} Shift Block Updated`,
  SHIFT_BLOCKS: `${appV2EventPrefix} Shift Blocks`,

  // Facility Notes
  WORKER_INSTRUCTIONS_VIEWED: `${appV2EventPrefix} Worker Instructions Viewed`,
  SAVE_FACILITY_RULES_CLICKED: `${appV2EventPrefix} Save facility rules button clicked`,

  // Magic Shifts
  MAGIC_SHIFT_INFO_VIEWED: `${appV2EventPrefix} Magic Shift Info Viewed`,

  // Billing Alerts
  FACILITY_PENDING_NOTICE_ENABLED: `${appV2EventPrefix} Pending notice enabled`,
  FACILITY_SUBMIT_PAYMENT_ALERT_CTA_CLICKED: `${appV2EventPrefix} Submit payment alert CTA clicked`,
  FACILITY_LEARN_MORE_LINK_CLICKED: `${appV2EventPrefix} Learn more link clicked`,

  // Documents
  WORKER_DOCUMENTS_PAGE_VIEWED: `${appV2EventPrefix} Worker documents page viewed`,
  WORKER_DOCUMENTS_PAGE_ACTIONS: `${appV2EventPrefix} Worker documents page actions`,
  WORKER_DOCUMENTS_DOWNLOAD_ALERT_TRIGGERED: `${appV2EventPrefix} Worker document download alert triggered`,

  // Login
  KEEP_ME_LOGGED_IN_CLICKED: `${appV2EventPrefix}: Keep me logged in clicked`,

  // Placement Candidates Admin events
  ADMIN_PLACEMENT_CANDIDATE_VIEWED: `${appV2EventPrefix} Admin placement candidate viewed`,
  ADMIN_PLACEMENT_CANDIDATE_UPDATED: `${appV2EventPrefix} Admin placement candidate updated`,
  ADMIN_PLACEMENT_CANDIDATE_RESUME_UPLOADED: `${appV2EventPrefix} Admin placement candidate resume uploaded`,

  // Placement Candidates Hire Professionals page events
  PLACEMENT_CANDIDATES_PAGE_VIEWED: `${appV2EventPrefix} Placement candidates page viewed`,
  PLACEMENT_CANDIDATES_PAGE_FILTER_CHANGED: `${appV2EventPrefix} Placement candidates page filter changed`,
  PLACEMENT_CANDIDATES_TILE_ACTION: `${appV2EventPrefix} Placement candidates tile action`,
  PLACEMENT_CANDIDATES_LOAD_MORE_CLICKED: `${appV2EventPrefix} Placement candidates load more clicked`,

  // Placement Candidates Details page events
  PLACEMENT_CANDIDATE_DETAILS_VIEWED: `${appV2EventPrefix} Placement candidate details page viewed`,
  PLACEMENT_CANDIDATE_DETAILS_ACTION: `${appV2EventPrefix} Placement candidate details page action`,
  PLACEMENT_CANDIDATE_SHARE_PROFILE_SUBMITTED: `${appV2EventPrefix} Placement candidate details share profile submitted`,
  PLACEMENT_CANDIDATE_OTP_LOGIN: `${appV2EventPrefix} Placement candidate OTP login`,
};

export const APP_V2_APP_EVENTS = {
  // App
  API_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} API Zod Schema validation error`,
  FEATURE_FLAG_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} Feature Flag Zod schema validation error`,
  GET_EXTRA_WORKED_TIME_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to get extra worked time request`,
  PATCH_EXTRA_WORKED_TIME_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to patch extra worked time request`,

  // Documents
  GET_WORKER_DOCUMENTS_FAILURE: `${appV2EventPrefix} Failed to get hcp documents`,
  GET_DOCUMENT_DISTRIBUTION_URL_FAILURE: `${appV2EventPrefix} Failed to get document distribution URL`,
  WORKER_DOCUMENTS_ACTION_FAILURE: `${appV2EventPrefix} Action failed on worker documents page`,

  // Licenses
  GET_WORKER_LICENSES_FAILURE: `${appV2EventPrefix} Failed to get worker licenses`,

  // Invoice History
  GET_INVOICE_BALANCES_FAILURE: `${appV2EventPrefix} Failed to get invoice balances`,
  GET_INVOICE_HISTORY_FAILURE: `${appV2EventPrefix} Failed to get invoice history`,
  GET_INVOICE_PAYMENT_LINK: `${appV2EventPrefix} Failed to get invoice payment link`,
  GET_BILLING_SPECIALIST_FAILURE: `${appV2EventPrefix} Failed to get facility billing specialist`,
  GET_TIMESHEET_URL: `${appV2EventPrefix} Failed to get timesheet url`,
  GET_UPCOMING_CHARGES: `${appV2EventPrefix} Failed to get upcoming charges`,

  // Invoice Disputes
  GET_INVOICE_FAILURE: `${appV2EventPrefix} Failed to get invoice`,
  GET_INVOICE_ITEMS_FAILURE: `${appV2EventPrefix} Failed to get invoice items`,
  GET_INVOICE_ITEM_FAILURE: `${appV2EventPrefix} Failed to get invoice item`,
  GET_INVOICE_DISPUTE_SUMMARY: `${appV2EventPrefix} Failed to get invoice dispute summary`,
  GET_INVOICE_DISPUTES_FAILURE: `${appV2EventPrefix} Failed to get invoices with disputes`,
  GET_INVOICE_TOTAL_FAILURE: `${appV2EventPrefix} Failed to get invoice total`,
  GET_INVOICE_ITEM_DISPUTES_FAILURE: `${appV2EventPrefix} Failed to get invoice item disputes`,
  GET_INVOICE_ID_FAILURE: `${appV2EventPrefix} Failed to get invoice id`,
  GET_FACILITY_QUIZ_FAILURE: `${appV2EventPrefix} Failed to get workplace quiz`,

  // Facility
  // TODO(clipboardhealth.atlassian.net/browse/TX-761): Remove const usage after the complete rollout and flag removal
  GET_FACILITY_SOLVE_UNPAID_WORKED_BREAKS_STAGE: `${appV2EventPrefix} Failed to load facility SolveWorkedUnpaidBreaks stage`,
  GET_FACILITY_STREAKS_FAILURE: `${appV2EventPrefix} Failed to get facility streaks`,
  GET_WORKER_DETAILS_AT_WORKPLACE_FAILRE: `${appV2EventPrefix} Failed to get worker details at workplace`,
  GET_EXCLUSION_FAILURE: `${appV2EventPrefix} Failed to get exclusion`,
  GET_FACILITY_NOTES_FAILURE: `${appV2EventPrefix} Failed to get facility notes`,
  GET_QUIZ_GENERATION_STATUS_FAILURE: `${appV2EventPrefix} Failed to get quiz generation status`,
  GET_FACILITY_USERS_FAILURE: `${appV2EventPrefix} Failed to get facility users`,
  GET_USER_FAILURE: `${appV2EventPrefix} Failed to get user`,

  // Agent
  GET_PREFERRED_WORKERS_FAILURE: `${appV2EventPrefix} Failed to get preferred workers`,
  GET_AGENT_PROFILE_FAILURE: `${appV2EventPrefix} Failed to load agent profile`,
  GET_AGENT_SEARCH_FAILURE: `${appV2EventPrefix} Failed to search agent`,

  // Timezone
  GET_AGENT_PROFILE_PICTURE_FAILURE: `${appV2EventPrefix} Failed to get agent profile picture`,
  GET_TIMEZONE_FAILURE: `${appV2EventPrefix} Failed to get timezone`,

  // Facility Profile
  GET_FACILITY_TIME_CARD_TEMPLATE_FAILURE: `${appV2EventPrefix} Failed to get facility time card template`,
  GET_FACILITY_PROFILE_FAILED: `${appV2EventPrefix} Failed to get facility profile`,
  GET_FACILITY_DETAILS_FAILURE: `${appV2EventPrefix} Failed to get facility details`,

  // Qualifications
  GET_QUALIFICATIONS_FAILURE: `${appV2EventPrefix} Failed to get qualifications`,
  GET_QUALIFICATION_FAILURE: `${appV2EventPrefix} Failed to get qualification`,
  GET_WORKER_TYPES_FAILURE: `${appV2EventPrefix} Failed to get worker types`,

  // Workplace Types
  GET_WORKPLACE_TYPES_FAILURE: `${appV2EventPrefix} Failed to get workplace types`,
  GET_WORKPLACE_TYPE_FAILURE: `${appV2EventPrefix} Failed to get workplace type`,

  // User
  SEARCH_USERS_FAILURE: `${appV2EventPrefix} Failed to search users`,

  SEARCH_AGENTS_FAILURE: `${appV2EventPrefix} Failed to search users`,
  SEARCH_FACILITIES_FAILURE: `${appV2EventPrefix} Failed to search facilities`,

  GET_FACILITY_STREAKS_SETTINGS_FAILURE: `${appV2EventPrefix} Failed to get facility streaks settings`,

  // Home Health
  HOME_HEALTH_ACCOUNTS_PRICING_GET_LIST_FAILURE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} Failed to get accounts pricing list`,
  HOME_HEALTH_GET_WORKER_ENABLED_FAILURE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} Failed to get worker enabled status`,
  HOME_HEALTH_WORKPLACE_WORKER_STATISTICS_GET_FAILURE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} Failed to get workplace worker statistics`,

  // Auth
  AUTH_TOKEN_NOT_AVAILABLE: `${appV2EventPrefix} Auth token not available`,
  SIGN_IN_WITH_EMAIL_LINK_FIREBASE_FAILURE: `${appV2EventPrefix} Failed to sign in with email link via Firebase`,
  SIGN_IN_WITH_CUSTOM_TOKEN_FIREBASE_FAILURE: `${appV2EventPrefix} Failed to sign in with custom token via Firebase`,
  SIGN_IN_WITH_EMAIL_PASSWORD_FIREBASE_FAILURE: `${appV2EventPrefix} Failed to sign in with email and password via Firebase`,

  // Magic Shifts
  SET_SHIFT_URGENT_FAILURE: `${appV2EventPrefix} Failed to set shift as urgent`,
  GET_FACILITY_DAY_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to get facility day shifts`,

  // Placement Candidate
  UPDATE_PLACEMENT_CANDIDATE_FAILURE: `${appV2EventPrefix} Failed to update placement candidate`,
  GET_OR_CREATE_PLACEMENT_CANDIDATE_FAILURE: `${appV2EventPrefix} Failed to get and create placement candidate`,

  // Quiz
  GET_IS_QUIZ_REQUIRED_FAILURE: `${appV2EventPrefix} Failed to get if quiz is required`,
};
